import React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';

const PromoModal: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(true);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <Modal
        fade
        backdrop
        isOpen={modalOpen}
        toggle={toggleModal}
        className="d-flex justify-content-center align-items-center flex-column h-100 promo-modal"
      >
        <ModalBody className="d-flex flex-column align-items-center">
          <img
            src={require('./assets/images/popup-modal.jpg')}
            alt="ADVENT ADVENT DAS BUNDLE BRENNT"
            className="img-fluid w-100 my-3"
          />
          <button className="btn-lg btn btn-secondary" onClick={toggleModal}>
            ZUM SHOP
          </button>
        </ModalBody>
      </Modal>
      {children}
    </>
  );
};

export default PromoModal;
